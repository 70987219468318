<template>
    <div>
        <custom-field v-if="value"
            :field="columnData.field"
            :disabled="!columnData.field.can_edit"
            :editable="columnData.field.can_edit"
            :value="value"
            :startupId="rowId"
            :trackerId="trackerId"
            :from-table="true"
            v-on:valueChanged="valueChanged"
        >
        </custom-field>
    </div>
</template>

<script>
import customField from "../customFields/CustomField";
import startups from "../../services/startups.service";
import trackersCompanies from "../../services/trackers-companies.service";

export default {
    props: {
        columnData: Object,
        rowId: Number,
        addData: Object,
    },

    data() {
        return {
            value: null
        }
    },

    components: {
        customField
    },

    computed: {
        trackerId() {
            if (typeof this.addData.trackerId !== "undefined") {
                return this.addData.trackerId
            }
            else {
                return 0
            }
        }
    },

    mounted() {
        if (this.columnData) {
            this.value = this.columnData.value
        }
    },

    watch: {
        columnData: {
            deep: true,
            handler()
            {
                this.value = this.columnData.value
            }
        }
    },

    methods: {
        valueChanged() {
            if (this.columnData.field.main_type == 'company_diligence') {
                startups.getCustomFieldValue(this.rowId, this.columnData.field.id).then(response => {
                    this.value = response.data
                })
            }

            if (this.columnData.field.main_type == 'tracker_diligence') {
                trackersCompanies.getDiligenceFieldValue(this.trackerId, this.rowId, this.columnData.field.id).then(response => {
                    this.value = response.data
                })

                this.$emit('action', {action: 'diligence-field-changed'})
            }
        }
    }
}
</script>

<style scoped>

</style>
